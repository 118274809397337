function navNew() {

    const addSpanCollapseIcon = (targetPar, childElem) => {
        var targetParent = $(targetPar),
            childElement = childElem;
        var headerSpanIconElement = '<span class="drop-down"> </span>';
        targetParent.each(function () {
            var _this = $(this);
            if (_this.find(childElement).length) {
                _this.append(headerSpanIconElement);
            }
        });
    };
    addSpanCollapseIcon('.header--nav li', 'ul');

    const clickToggle = (ClickEle, targetElem, tarEleType, addingClass, toggleActive, toggleDura) => {
        var clickElement = $(ClickEle),
            targetElement = targetElem,
            elementType = tarEleType,
            activeClass = addingClass,
            toggleDuration = toggleDura;
        clickElement.on("click", function () {
            var _this = $(this);
            if (elementType == 'previous') {
                _this.prev(targetElement).slideToggle(toggleDuration);
            } else if (elementType == 'next') {
                _this.next(targetElement).slideToggle(toggleDuration);
            } else {
                $(targetElement).slideToggle(toggleDuration);
                $(targetElement).toggleClass(activeClass);
            }
            _this.toggleClass(activeClass);
            _this.parent().toggleClass(toggleActive);
        });
    };
    clickToggle('.header--nav span.drop-down', 'ul', 'previous', 'active', 'toggle-active', 400);

    var headerHamburger = $('.header--hamburger');

    headerHamburger.on("click", function () {
        var _this = $(this);
        $('body').toggleClass('menu-active');
        $('html').toggleClass('menu-visible');
        _this.toggleClass('active');
    });

}

// ############## Smooth scroll functions ##################

function focusPromotion() {
    $('html, body').animate({
        scrollTop: $("#promotions").offset().top - 84
    }, 0);
};

function focusDownload() {
    $('html, body').animate({
        scrollTop: $("#downloads").offset().top - 84
    }, 0);
};

function focusAboutUs() {
    $('html, body').animate({
        scrollTop: $("#about-us").offset().top - 84
    }, 0);
};